.c-form-newsletter {
  legend {
    max-width:420px;
    width:100%;
  }
  label {
    cursor:pointer;
  }
  input[type="text"] ,
  button
  {
    padding:16px 20px;
    border-radius:40px;
    border:2px solid #333;
    display:block;
    margin:16px auto;
    text-align:center;
    min-width:60%;
    box-sizing: border-box;
    outline:0;
  }
  input[type="checkbox"]{
    margin-right:.5em;
  }
  a {
    &:hover {
      text-decoration:underline;
    }
  }
  @include mobile-xs {
    padding-bottom:30px;
  }
}