// e-margin
.e-margin {
  &-auto {
    margin:auto !important;
    &-left {
      margin-left:auto !important;
    }
    &-right {
      margin-right:auto !important;
    }
    &--mobile {
      @include mobile {
        margin:auto !important;
      }
    }
  }
  &-0 {
    margin:0 !important;
  }
  &-bottom-em {
    &-0 {
      margin-bottom: 0 !important;
    }
    &-025 {
      margin-bottom: 0.25em !important;
    }
    &-05 {
      margin-bottom: .5em !important;
    }
    &-1 {
      margin-bottom: 1em !important;
    }
    &-2 {
      margin-bottom: 2em !important;
    }
    &-3 {
      margin-bottom: 3em !important;
    }
    &-4 {
      margin-bottom: 4em !important;
    }
    &-5 {
      margin-bottom: 5em !important;
    }
    &-6 {
      margin-bottom: 6em !important;
    }
    &-7 {
      margin-bottom: 7em !important;
    }
    &-8 {
      margin-bottom: 8em !important;
    }
    &-9 {
      margin-bottom: 9em !important;
    }
    &-10 {
      margin-bottom: 10em !important;
    }
  }
  &-top-em {
    &-0 {
      margin-top: 0em !important;
    }
    &-03 {
      margin-top: .3em !important;
    }

    &-05 {
      margin-top: .5em !important;
    }
    &-1 {
      margin-top: 1em !important;
    }
    &-2 {
      margin-top: 2em !important;

    }
    &-3 {
      margin-top: 3em !important;

    }
    &-4 {
      margin-top: 4em !important;

    }
    &-5 {
      margin-top: 5em !important;
    }
    &-6 {
      margin-top: 6em !important;
    }
    &-7 {
      margin-top: 7em !important;
    }
    &-8 {
      margin-top: 8em !important;
    }
    &-9 {
      margin-top: 9em !important;
    }
    &-10 {
      margin-top: 10em !important;
    }
  }
  &-top {
    &-auto {
      margin-top:auto !important;
    }
    &-05 {
      margin-top:0.5em !important;
    }
    &-0 {
      margin-top:0em !important;

    }
    &-1 {
      margin-top:1em !important;

    }
    &-2 {
      margin-top:2em !important;

    }
  }
  &-left-em {
    &-1 {
      margin-left: 1em !important;
    }
  }
  &-right {
    &-auto {
      margin-left:inherit;
      margin-right:auto !important;
    }
  }
  &--no-mobile {
    @media screen and (max-width: $mobile){
      &-0 {
        margin:0 !important;
      }
    }
  }
  &-only-mobile {
    @include mobile {
      &-bottom-em-1 {
        margin-bottom: 1em !important;
      }
      &-bottom-em-2 {
        margin-bottom: 2em !important;
      }
      &-bottom-em-3 {
        margin-bottom: 3em !important;
      }
    }
  }
  &-bottom-0 {
    margin-bottom:0 !important;
    &--mobile {
      @include mobile {
        margin-bottom:0 !important;
      }
    }
  }
}


// .e-margin-bottom-em-0--mobile
// .e-margin MOBILE
.e-margin {
  @include mobile {
    &-bottom-em {
      &-0--mobile {
        margin-bottom: 0 !important;
      }
      &-05--mobile {
        margin-bottom: .5em !important;
      }
      &-1--mobile {
        margin-bottom: 1em !important;
      }
      &-2--mobile {
        margin-bottom: 2em !important;
      }
      &-3--mobile {
        margin-bottom: 3em !important;
      }
      &-4--mobile {
        margin-bottom: 4em !important;
      }
      &-5--mobile {
        margin-bottom: 5em !important;
      }
    }
    &-top-em {

      &-0--mobile {
        margin-top: 0em !important;

      }

      &-05--mobile {
        margin-top: .5em !important;

      }

      &-1--mobile {
        margin-top: 1em !important;

      }
      &-2--mobile {
        margin-top: 2em !important;

      }
      &-3--mobile {
        margin-top: 3em !important;

      }

      &-4--mobile {
        margin-top: 4em !important;

      }

      &-5--mobile {
        margin-top: 5em !important;

      }
    }
  }
}
