// VENDOR >> modified
.material-scrolltop {
	cursor: pointer;
	background-color: rgba($branding_gray--darken,1);
	bottom:30px;
  right:30px;
  &, 
  &::before {
    background-image: url(#{$path}assets/svg/arrow_top.svg);
    background-position: center 50%;
    background-repeat: no-repeat;
    background-size:50% 50%;
  }
  &:hover {
    background-color:$branding_red;
    background-image: url(#{$path}assets/svg/arrow_top.svg);
    }
  &:hover {
    &::before {
    background-color:$branding_red;
    background-image: url(#{$path}assets/svg/arrow_top.svg);
    }
  }
  &.reveal {
    width: 50px;
    height: 50px;
    z-index: 1 !important;
  }
  @include mobile {
    bottom:6px;
    right:6px;
    display:none !important;
  }
 
}
