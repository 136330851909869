.c-banner-acces {
  img {
    display: block;
    @extend .dim;
  }
  dl {
    margin:30px 0;
    dt {
      margin-right:40px;
      @include mobile {
       margin-right:12px; 
      }
      &:last-child {
        margin-right:0;
      }
    }
  }
}