.c-breadcrumb {
  @include reset_list;
  font-weight: 600;
  color:#777;
  margin:2em 0;
  a {
    text-decoration:none;
    color:#444;
    transition: 1s color;
    font-weight: 700;
    &:hover {
      color:#000;
      text-decoration:underline;
    }
  }
  ul {
    vertical-align:middle;
    display:flex;
    flex-wrap:wrap;
    li {
      display:inline-block;
      position:relative;
      margin-right:.5em;
      &::after {
        display:inline-block;
        content:(">>");
        margin-left:.5em;
      }
      &:last-child {
        &::after {
          display:none;
        }
      }
    }
  }
  @include mobile-xs {
    font-size: .8em;
    margin:2em 0 0 0;
  }
}