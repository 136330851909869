.c-order-received {
  &__list {
    li {
      border-right-style:dotted;
      &:last-child {
        border-right:0;
      }
    }
  }
  &__table {
    width:100%;
    text-align:left;
    border:1px solid $branding_gray--light3;
    border-radius:8px;
    tr th,
    tr td {
      padding:1em;
      border-bottom:1px solid $branding_gray--light3;
    }
  }
}