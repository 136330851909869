.c-acces-message {
  display:flex;
  position: relative;
  display:flex;
  margin:2em 0 6em;
  .c-acces-message__content {
    background-color:$branding_gray--light;
    padding:5% 10% 0%;

  }
  .c-acces-message__image {
    flex:1;
    background-repeat:no-repeat;
    background-size:cover;
  }

  @include mobile {
    display:flex;
    flex-wrap:wrap;
    margin:2em 0 2em;
    .c-acces-message__content {
      width:100%;
      padding:20px 20px 80px 20px;
      display:block;
      order:1;
      text-align:center;

    }
    .c-acces-message__image {
      order:0;
      flex:initial;
      background-repeat:no-repeat;
      background-size:cover;
      height:200px;
      width:100%;
      display:block;
    }
  }
}