// e-padding 
.e-padding {
	&-0 {
		padding:0 !important;
	}
	&-right-0 {
		padding-right:0 !important;
	}
	&-left-0 {
		padding-left:0 !important;
	}
	&-top-0 {
		padding-top:0 !important;
	}
	&-top-px {
		&-10 {
			padding-top:10px !important;
		}
		&-20 {
			padding-top:20px !important;
		}
		&-25 {
			padding-top:25px !important;
		}
		&-30 {
			padding-top:30px !important;
		}
		&-40 {
			padding-top:40px !important;
		}
		&-50 {
			padding-top:50px !important;
		}
		&-60 {
			padding-top:60px !important;
		}
		&-75 {
			padding-top:75px !important;
		}
		&-100 {
			padding-top:100px !important;
		}
	}
	&-bottom-px {
		&-10 {
			padding-bottom:10px !important;
		}
		&-20 {
			padding-bottom:20px !important;
		}
		&-25 {
			padding-bottom:25px !important;
		}
		&-30 {
			padding-bottom:30px !important;
		}
		&-50 {
			padding-bottom:50px !important;
		}
		&-50 {
			padding-bottom:50px !important;
		}
		&-60 {
			padding-bottom:60px !important;
		}
		&-75 {
			padding-bottom:75px !important;
		}
		&-100 {
			padding-bottom:100px !important;
		}
	}
	&-right-px {
		&-0 {
			padding:0px !important;
		}
		&-10 {
			padding-right:10px !important;
		}
		&-20 {
			padding-right:20px !important;
		}
		&-25 {
			padding-right:25px !important;
		}
		&-30 {
			padding-right:30px !important;
		}
		&-40 {
			padding-right:40px !important;
		}
		&-50 {
			padding-right:50px !important;
		}
	}
	&-left-px {
		&-0 {
			padding-left:0px !important;
		}
		&-10 {
			padding-left:10px !important;
		}
		&-20 {
			padding-left:20px !important;
		}
		&-25 {
			padding-left:25px !important;
		}
		&-30 {
			padding-left:30px !important;
		}
		&-40 {
			padding-left:40px !important;
		}
	}
	
	&-only-mobile {
		@include mobile {
			&-bottom-px {
				&-10 {
					padding-bottom:10px !important;
				}
				&-20 {
					padding-bottom:20px !important;
				}
				&-30 {
					padding-bottom:30px !important;
				}
				&-50 {
					padding-bottom:50px !important;
				}
			}
		}
	}
}


// .e-margin :: MOBILE
.e-padding {
	@include mobile {
		&-0--mobile {
			padding:0 !important;
		}
		&-top-px {
			&-0--mobile {
				padding-top:0px !important;
			}
			&-10--mobile {
				padding-top:10px !important;
			}
			&-20--mobile {
				padding-top:20px !important;
			}
			&-30--mobile {
				padding-top:30px !important;
			}
			&-25--mobile {
				padding-top:25px !important;
			}
			&-40--mobile {
				padding-top:40px !important;
			}
			&-50--mobile {
				padding-top:50px !important;
			}
			&-60--mobile {
				padding-top:60px !important;
			}
			&-75--mobile {
				padding-top:75px !important;
			}
			&-100--mobile {
				padding-top:100px !important;
			}
		}
		&-bottom-px {
			&-0--mobile {
				padding-bottom:0px !important;
			}
			&-10--mobile {
				padding-bottom:10px !important;
			}
			&-20--mobile {
				padding-bottom:20px !important;
			}
			&-25--mobile {
				padding-bottom:25px !important;
			}
			&-40--mobile {
				padding-bottom:40px !important;
			}
			&-30--mobile {
				padding-bottom:30px !important;
			}
			&-50--mobile {
				padding-bottom:50px !important;
			}
			&-60--mobile {
				padding-bottom:60px !important;
			}
			&-75--mobile {
				padding-bottom:75px !important;
			}
		}
		&-right-px {
			&-0--mobile {
				padding-right:0px;
			}
			&-10--mobile {
				padding-right:10px;
			}
		}
		&-right-px {
			&-20--mobile {
				padding-right:20px;
			}
		}
		&-right-px {
			&-30--mobile {
				padding-right:30px;
			}
		}
		&-right-px {
			&-40--mobile {
				padding-right:40px;
			}
		}
		&-right-px {
			&-50--mobile {
				padding-right:50px;
			}
		}

		&-left-px {
			&-0--mobile {
				padding-left:0px !important;
			}
		}
		&-left-px {
			&-10--mobile {
				padding-left:0px;
			}
		}
		&-left-px {
			&-20--mobile {
				padding-left:20px;
			}
		}
		&-left-px {
			&-30--mobile {
				padding-left:30px;
			}
		}
		&-left-px {
			&-40--mobile {
				padding-left:40px;
			}
		}
		&-left-px {
			&-50--mobile {
				padding-left:50px;
			}
		}
	}
}

// .e-margin-bottom-em-0--mobile
// .e-margin MOBILE
.e-margin {
	@include mobile {
		&-bottom-em {
			&-0--mobile {
				margin-bottom: 0 !important;
			}
			&-05--mobile {
				margin-bottom: .5em !important;
			}
			&-1--mobile {
				margin-bottom: 1em !important;
			}
			&-2--mobile {
				margin-bottom: 2em !important;
			}
			&-3--mobile {
				margin-bottom: 3em !important;
			}
			&-4--mobile {
				margin-bottom: 4em !important;
			}
			&-5--mobile {
				margin-bottom: 5em !important;
			}
		}
		&-top-em {

			&-0--mobile {
				margin-top: 0em !important;

			}

			&-05--mobile {
				margin-top: .5em !important;

			}

			&-1--mobile {
				margin-top: 1em !important;

			}
			&-2--mobile {
				margin-top: 2em !important;

			}
			&-3--mobile {
				margin-top: 3em !important;

			}

			&-4--mobile {
				margin-top: 4em !important;

			}

			&-5--mobile {
				margin-top: 5em !important;

			}
		}
	}
}