.c-search {
  margin:auto;
  display:flex;
  flex-direction:row;
  width:100%;
  justify-content:center;

  input , button {
    @include reset;
  }
  
  .tt-dropdown-menu, .tt-menu
  {
    left:10px !important;
  }
  form {
    display:flex;
    width:100%;
    justify-content:center;

    .autocomplete {
      display:flex;
      width:100%;
      flex:1;
      justify-content:center;
    }
    input ,  span.twitter-typeahead 
    // span.twitter-typeahead 
    {
      height:48px;
      border:1px solid gray;
      padding:0 10px;
      max-width:420px;
      height:100%;
      width:100%;
      flex:1;
      background-color:#FFF;
      position:relative;
      z-index:1;
      // input.tt-hint {
        top:50% !important;
        transform:translateY(-50%) !important;
        left:10px !important;
        // display:none !important;
      // }
      &#c-search__input ,
      input#c-search__input
      {
        // border:3px solid red;
        height:48px;
        width:100%;

      } 
    }
    span.twitter-typeahead  {
      border:0;
      left:0px !important;
      padding:0;
    }
    button {
      height:100%;
      width:60px;
      cursor: pointer;
      background-color:gray;
      transition: .2s;
      &:hover {
        background-color:$branding_gray--darken;
      }
      i {
        svg {
          max-height:36px;
          padding:6px 0;
          path {
            fill:#FFF;
          }
        }
      }
    }
  }



  .autocomplete {
    position: relative;
    // display: inline-block;
  }


  .autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
  }

  .autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff; 
    border-bottom: 1px solid #d4d4d4; 
  }

  /*when hovering an item:*/
  .autocomplete-items div:hover {
    background-color: #e9e9e9; 
  }

  /*when navigating through the items using the arrow keys:*/
  .autocomplete-active {
    background-color: DodgerBlue !important; 
    color: #ffffff; 
  }
}