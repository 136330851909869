.c-cart-form {

  .c-cart-form__table {
    border-collapse: collapse;
    width:100%;
    border:1px solid #CCC;
    text-align: left;

  }

  .c-cart-form__head {

    th {
      border-bottom:1px solid #CCC;
      padding:12px 8px;
      vertical-align:middle;
      font-size: .9em;
      font-weight: 600;
      background-color:#f5f5f5;
    }
  }

  .c-cart-form__body {
    td {
      border:1px solid #EFEFEF;
      height:40px;
      padding:6px 6px;
      vertical-align:middle;
      @include mobile {
        padding:10px 2px;
        border:0;
        &::before {
          content: attr(data-title);
          font-weight: 700;
          float: left;
        }
      }
    }
  }
  .item-remove {
    text-align:center;
    width:30px;
    i {
      svg {
        width:16px;
        height:16px;
      }
    }
  }
  .item-thumbnail {
    max-width:30px;
    img {
      width:60px;
      display:block;
      margin:0 auto;
      border:1px solid #CCC;
      object-fit: contain;
    }
  }
  .item-product {
    font-weight: 500;
  }
  .item-quantity {
    // max-width:30px;
    width:105px;
    input {
      width:96%;
      text-align:center;
      margin:0 auto;
      display:block;
    }
  }
  .item-price {
    max-width:40px;
    text-align: right;
  }
  .item-total {
    text-align: right;
    max-width:40px;
  }

  .cart_totals 
  {
    border-collapse: collapse;
    width:100%;
    border:1px solid $branding_gray--light3;
    text-align: left;
    border-radius:8px;
    border-collapse: separate;
    border-spacing: 0;
    padding-bottom:2px;
    .cart-subtotal ,
    .cart-total
    {
      th {
        width:35%;
        padding:12px;
        border-bottom:1px solid $branding_gray--light3
      }
      td {
        padding:12px;
        border-bottom:1px solid $branding_gray--light3;
      }
    }
  }

  @include mobile {

    

    .c-cart-form__body {

      table , tbody , tr , td {
        display:block;
        max-width: auto;
        width:auto;
        height:auto;
      }
    }

    tr:nth-child(even) {
      background-color:#fafafa
    }


    .c-cart-form__head {

      display:none;
    }

    .c-cart-form__body {
      display:flex !important;
      flex-wrap: wrap;
      width:100%;

      td {
        height:auto;
        width:100%;
        max-width:100%;
        border-bottom:1px solid $branding_gray--light3;
      }
      .item-remove 
      {
        width:auto;
      }
      .item-thumbnail {
        flex:1;
      }
      .item-quantity {
        text-align:right;
        input {
          // width:80px;
          // float:right;
        }
      }
       border-top:1px dotted #999;
       padding-bottom: 32px;
    }

  }


}