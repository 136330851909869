// Iframe calculate ratio %
.u-ratio {
	
	.u-ratio-16-9,
	.u-ratio-16-10,
	.u-ratio-square {
	    display: block;
	    position: relative;
	    width: 100%;
	    height: 0;
	}
	.u-ratio-16-9 {
	    padding-top: 56.25%; /* This is your aspect-ratio */
	}

	.u-ratio-16-10 {
	    padding-top: 62.5%; /* This is your aspect-ratio */
	}

	.u-ratio-square {
	    padding-top: 100%; /* This is your aspect-ratio */
	}
	.u-ratio-inner {
	    display: block;
	    position: absolute;
	    left: 0;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    width: 100%;
	    height: 100%;
	}
}



// https://css-tricks.com/aspect-ratio-boxes/
// Example:  <div style="--aspect-ratio:16/9;">
// 
[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}
[style*="--aspect-ratio"] > img {  
  height: auto;
} 
@supports (--custom:property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }
  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }  
  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }  
}


@include mobile {
	[style*="--mobile-aspect-ratio"] > :first-child {
	  width: 100%;
	}
	[style*="--mobile-aspect-ratio"] > img {  
	  height: auto;
	} 
	@supports (--custom:property) {
	  [style*="--mobile-aspect-ratio"] {
	    position: relative;
	  }
	  [style*="--mobile-aspect-ratio"]::before {
	    content: "";
	    display: block;
	    padding-bottom: calc(100% / (var(--mobile-aspect-ratio)));
	  }  
	  [style*="--mobile-aspect-ratio"] > :first-child {
	    position: absolute;
	    top: 0;
	    left: 0;
	    height: 100%;
	  }  
	}
}
