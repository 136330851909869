.c-order-products {
  background-color:#f5f5f5;
  position:relative;
  display:block;
  width:100%;
  margin:1.5em 0;
  padding:5px 12px;
  text-align: right;
  select {
    background:#FFF;
    padding: 8px 20px;
    border: none;
    border-bottom: 1px solid #e4e4e4;
    font-size: 1rem;
    outline: 0;
    cursor: pointer;
    color:$branding_gray;
    font-weight:bold;
  }
}