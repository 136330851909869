.e-c-acces-sections-animated {
	animation: e-c-acces-sections-animated .4s cubic-bezier(0.790, 0.575, 0.565, 1.000) both;
}
@keyframes e-c-acces-sections-animated {
	0% {
		opacity: 0;
		display:none;
		background-size:57% 57%;
	}
	100% {
		opacity: 1;
		display:block;
		background-size:40% 40%;
		background-repeat:no-repeat;
		background-position:center 40%;

	}
}

.e-slide-in-bottom {
	animation: e-slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@keyframes e-slide-in-bottom {
	0% {
		transform: translateY(-300px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}


.e-fade-in-left {
	animation: e-fade-in-left .8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation-delay: 0s;

}
@keyframes e-fade-in-left {
	0% {
		transform: translateX(-3vw) scale(1);
		opacity: 0;

	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}




.e-fade-out-left {
	animation: e-fade-out-left .8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation-delay: 0s;

}
@keyframes e-fade-out-left {
	0% {
		transform: translateX(0vw) scale(1);
		opacity: 1;

	}
	100% {
		transform: translateX(-10vw);
		opacity: 0;
	}
}




.e-animation-text-focus-in-100 {
	animation: e-animation-text-focus-in-100 .5s cubic-bezier(0.150, 0.085, 0.680, 0.530) both;
}
@keyframes e-animation-text-focus-in-100 {
	0% {
		filter: blur(12px);
		opacity: 0;

	}
	100% {
		filter: blur(0px);
		opacity: 1;


	}
}


.e-fade-in {
	animation: e-fade-in .8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@keyframes e-fade-in {
	0% {
		opacity: 0;
		display:none;

	}
	100% {
		opacity: 1;
		display:block;

	}
}

.e-fade-in-menu {
  animation: .1s e-fade-in-menu 0s cubic-bezier(0.150, 0.085, 0.680, 0.530) both;
}
@keyframes e-fade-in-menu {
  0% {
    filter: blur(1px);
    opacity: 0;
    transform:translateY(-16px);
  }
  100% {
    filter: blur(0px);
    opacity: 1;


  }
}