/*
Media Query Extensions:
-ns = not-small
-m  = medium
-l  = large
*/
.mr-auto  { margin:auto; }
.mrh-auto { margin: auto 0; }
.mrv-auto { margin: 0 auto; }
.mrt-auto { margin-top: auto; }
.mrb-auto { margin-bottom: auto; }
@media #{$breakpoint-not-small}{
	.mr-auto-ns  { margin:auto; }
	.mrh-auto-ns { margin: auto 0; }
	.mrv-auto-ns { margin: 0 auto; }

	.mrt-auto-ns { margin-top: auto; }
	.mlb-auto-ns { margin-bottom:  auto; }
}
@media #{$breakpoint-medium}{
	.mr-auto-m  { margin:auto; }
	.mrh-auto-m { margin: auto 0; }
	.mrv-auto-m { margin: 0 auto; }

	.mrt-auto-m { margin-top: auto; }
	.mlb-auto-m { margin-bottom:  auto; }
}
@media #{$breakpoint-large}{
	.mr-auto-l  { margin:auto; }
	.mrh-auto-l { margin: auto 0; }
	.mrv-auto-l { margin: 0 auto; }

	.mrt-auto-l { margin-top: auto; }
	.mlb-auto-l { margin-bottom:  auto; }
}