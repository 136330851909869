.c-pagination 
{
  @include reset_list;
  text-align:center;
  display:block;
  width:100%;
  margin-top:2em;
  margin-bottom:2em;
  ul {
    .c-pagination__item ,
    .page-item
    {
      display:inline-block;
      width:46px;
      a,
      span 
      {
        text-decoration:none;
        width:40px;
        height:40px;
        line-height:40px;
        display:block;
        border:1px solid #A3A3A3;
        border-radius:50%;
        background-color:#A3A3A3;
        color:#FFF;
        font-weight:600;
        &:hover {
          background-color:$branding_red;
          color:#FFF;
        }
      }
      &.is-active
      {
        a {
          background-color:$branding_red;
          color:#FFF;
        }
      }
      &--btn {
        a {
          background-color:transparent;
          color:#A3A3A3;
          font-weight:bold;
          font-size: 1.1rem;
        }
      }
      &.active {
        span {
         background-color:$branding_red;
         color:#FFF;
        }
      }
      &:first-child ,
      &:last-child
      {
        .page-link {
         background-color:transparent;
         color:#A3A3A3;
         font-weight:bold;
         font-size: 1.1rem;
         &:hover {
          background-color:$branding_red;
          color:#FFF;
         } 
        }
      }
    }
  }
}