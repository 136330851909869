.header {
  .header__top {
    i {
      svg {
        width:10px;
      }
    }
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .header__middle {

  }
  .header__bottom {
    box-shadow: 0px 2px 5px #222;
  }
  .header__logo {
    position:relative;
    margin-top: -30px;
    img {
      max-width: 220px;
    }
  }
  .header__cart ,
  .header__user
  {
    svg {
      width:30px;
    }
    span {
      background-color:$branding_red;
      color:#FFF;
      width:30px;
      height:30px;
      line-height: 30px;
      text-align:center;
      border-radius:50%;
      font-weight:bold;
      font-size: .9em;
      top:-1.2em;
      right:-1.7em;
    }
  }
  .header__cart:hover ,
  .header__user:hover ,
  #body.body--page-comanda & .header__cart 

  {
    svg {
      path {
        fill: $branding_red;
      }
    }
  }
  @include mobile {
    .header__cart ,
    .header__user
    {
      svg {
        width:20px;
      }
      span {
        background-color:$branding_red;
        color:#FFF;
        width:30px;
        height:30px;
        line-height: 30px;
        text-align:center;
        border-radius:50%;
        font-weight:bold;
        font-size: .9em;
        top:-1.2em;
        right:-1.7em;
      }
    }
  }
}