.c-news {
  .item-date {
    font-weight:bold;
    font-size: .8em;
    margin:0;
    padding:0;
  }
  &__article {
    &-container {
      overflow:hidden;
      figure {
        position:relative;
        transition:.3s all;
        overflow:hidden;
        img {
          transition:.2s all;
        }
        figcaption {
          transition:.2s all;
          opacity: 0;
          position:absolute;
          z-index:999;
          top:50%;
          left:50%;
          transform:translate(-50% , -50%) scale(1.1);
          transform-origin:center center;
          font-size:4em;
          border:2px solid #FFF;
          border-radius:50%;
          width:1em;
          height:1em;
          text-align:center;
          line-height:.9em;
          display:block;
          color:#FFF;
          background-color:$branding_red;
        }
        &:hover {
          opacity: .8;
          figcaption {
          transform:translate(-50% , -50%) scale(1.1);
          transform-origin:center center;
           opacity: 1;
         }
         img {
          transform:scale(1.1);
         }
       }
     }
   }
   &--single {
    figure {
      &:hover {
        img {
          transform:scale(1);
        }
      }
    }
   }
   &-content {
    min-height: 350px;
   }
 }
}