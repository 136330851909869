// E-text increase & decrement
.e-text-increase-0 {
	&-9 {
		font-size: .9em;
	}
	&-8 {
		font-size: .8em;
	}
	&-7 {
		font-size:.7em;
	}
}
.e-text-increase-1 {
	font-size: 1em;
	@include mobile {
		&--mobile {
			font-size: 1em !important;
		}
	}
	&-1 {
		font-size: 1.1em !important;
		@include mobile {
			&--mobile {
				font-size: 1.1em !important;
			}
		}
	}
	&-2 {
		font-size: 1.2em !important;
		@include mobile {
			&--mobile {
				font-size: 1.2em !important;
			}
		}
	}
	&-3 {
		font-size: 1.3em !important;
		@include mobile {
			&--mobile {
				font-size: 1.3em !important;
			}
		}
	}
	&-4 {
		font-size:1.4em !important;
		@include mobile {
			&--mobile {
				font-size: 1.4em !important;
			}
		}
	}
	&-5 {
		font-size: 1.5em !important;
		@include mobile {
			&--mobile {
				font-size: 1.5em !important;
			}
		}
	}
	&-6 {
		font-size: 1.6em !important;
		@include mobile {
			&--mobile {
				font-size: 1.6em !important;
			}
		}
	}
	&-7 {
		font-size: 1.7em !important;
		@include mobile {
			&--mobile {
				font-size: 1.7em !important;
			}
		}
	}
	&-8 {
		font-size: 1.8em !important;
		@include mobile {
			&--mobile {
				font-size: 1.8em !important;
			}
		}
	}
	&-9 {
		font-size: 1.9em !important;
		@include mobile {
			&--mobile {
				font-size: 1.9em !important;
			}
		}
	}

}
.e-text-increase-2 {
	font-size: 2em !important;
	&-1 {
		font-size: 2.1em !important;
	}
	&-2 {
		font-size: 2.2em !important;
	}
	&-3 {
		font-size: 2.3em !important;
	}
	&-4 {
		font-size:2.4em !important;
	}
	&-5 {
		font-size:2.5em !important;
	}
	&-6 {
		font-size:2.6em !important;
	}
	&-7 {
		font-size:2.7em !important;
	}
}
.e-text-increase-3 {
	font-size: 3em !important;
	&-1 {
		font-size: 3.1em !important;
	}
	&-2 {
		font-size: 3.2em !important;
	}
	&-3 {
		font-size: 3.3em !important;
	}
	&-4 {
		font-size:3.4em !important;
	}
}
.e-text-increase-3 {
	font-size: 3em;
	&-1 {
		font-size: 3.1em;
	}
	&-2 {
		font-size: 3.2em;
	}
	&-3 {
		font-size: 3.3em;
	}
	&-4 {
		font-size:3.4em;
	}
}
.e-text-decrement-1 {
	font-size: 1em;
}
.e-text-decrement-0 {
	&-9 {
		font-size: .9em !important;
	}
	&-8 {
		font-size: .8em !important;
	}
	&-7 {
		font-size: .7em !important;
	}
	&-7 {
		font-size: .6em !important;
	}
	&-5 {
		font-size: .5em !important;
	}
}
.e-text-em-1-3-mobile {
	@include mobile {
		font-size: 1.3em !important;
	}
}
.e-text-em-1-9-mobile {
	@include mobile {
		font-size: 1.9em !important;
	}
}