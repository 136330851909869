// Object
.e-fill {
  object-fit: fill; 
}

.e-contain {
  object-fit: contain; 
}

.e-cover {
  object-fit: cover; 
}

.e-scale-down {
  object-fit: scale-down; 
}
.e-object-position-top-percent-10 {
  object-position: 0 10%; 
}
.e-object-position-top-percent-20 {
  object-position: 0 20%; 
}
.e-object-position-top-percent-30 {
  object-position: 0 30%; 
}
.e-object-position-top-percent-40 {
  object-position: 0 40%; 
}
.e-object-position-top-percent-50 {
  object-position: 0 50%; 
}

.e-object-position-top-percent-70 {
  object-position: 0 70%; 
}
.e-object-position-top-percent-60 {
  object-position: 0 60%; 
}
.e-object-position-top-percent-80 {
  object-position: 0 80%; 
}  
.e-object-position-top-percent-90 {
  object-position: 0 90%; 
}
.e-object-position-top-percent-100 {
  object-position: 0 100%; 
}