// E-CONTAINER - CONTAINER
.e-grid-container , 
.e-container,
.grid-container , 
.container 
{
	@include grid-container;
	.row {
		margin-right:0;
		margin-left:0;
	}
}
.e-grid-container-full , 
.e-container-full,
.grid-container-full , 
.container-full 
{
	width:100%;
	max-width: 100%;
	.row {
		margin-right:0;
		margin-left:0;
	}
}