.c-slider-home {
  display: block;
  overflow: hidden;
  figure {
    width:100%;
    overflow: hidden;
    position: relative;
    &.is-selected {
      img {
        animation: scale-in-center 2s cubic-bezier(.8, .8, .5, .5) both;
      }
    }
    &.item-shadow {
      &::before {
        content:("");
        position:absolute;
        width:50%;
        height:100%;
        top:0;
        right:0;
        background: linear-gradient(90deg, transparent 0%, rgba(#FFF,.6) 35%, rgba(#EFEFEF,1) 100%);
        z-index:1;
        @include mobile {
          width:60%;
        }
      }
    }
    figcaption {
      position:absolute;
      z-index: 1;
      top:50%;
      transform:translateY(-50%);
      right:5%;
      width:30%;
      @include mobile {
        width:50%;
        right:5%;
      }
      h2 {
        font-size: calc(1.2em + 1vw);
        font-weight: bold;
        @include mobile {
          font-size: calc(.8em + 1vw);
        }
      }
      h3 {
        color:$branding_red;
        font-weight: 700;
        font-size: calc(.3em + 1vw);
      }
      .e-btn {
        margin-top: 1.5em;
        display:inline-block;
        font-size: calc(.3em + .5vw);
        @include mobile {
          padding:6px;
        }
      }
    }
  }
  img {
    display: block;
  }
  @keyframes scale-in-center {
    0% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
      // filter: blur(1px) grayscale(0) sepia(0%) contrast(120%) brightness(120%);
      opacity: 0.8;
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }
  .flickity-page-dots {
    margin-bottom:40px;
    @include mobile {
      margin-bottom: 25px;
    }
    .dot {
      display: inline-block;
      width: 14px;
      height: 14px;
      margin: 0 8px;
      background: #333;
      border-radius: 50%;
      opacity: .30;
      cursor: pointer;
      @include mobile {
       width: 9px;
       height: 9px;
       margin: 0 4px;
     }

     &.is-selected {
      background-color:$branding_red;
      opacity: 1;
    }
  }
}
.flickity-prev-next-button.previous {
  left: 30px;
  border:3px solid $branding_gray;
  @include mobile {
    left:5px;
  }

}
.flickity-prev-next-button.next {
  right: 30px;
  border:3px solid $branding_gray;
  @include mobile {
    right:5px;
  }

}
@include mobile {
  .flickity-prev-next-button {
    top: 50%;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    transform: translateY(-50%);
  }
}
}